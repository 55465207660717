import React from "react"
import Slider from "react-slick"
import BlockWrapper from "../../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-duotone-svg-icons"

const SliderGalleryBlock = ({ block }) => {
  function NextArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <FontAwesomeIcon icon={faArrowRight} size="1x" />
        </span>
      </span>
    )
  }

  function PrevArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <FontAwesomeIcon icon={faArrowLeft} size="1x" />
        </span>
      </span>
    )
  }

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    mobileFirst: true,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <button page={i + 1}>
        {i + 1}/{block.image_items?.length}
      </button>
    ),
  }

  return (
    <BlockWrapper block={block} showHeadline="true" blockPadding="py-10">
      <div className="pt-8">
        <Slider {...settings}>
          {block.image_items &&
            block.image_items.length > 0 &&
            block.image_items.map((item, index) => (
              <figure className="relative" key={index}>
                <div
                  className="relative w-full"
                  style={{ paddingBottom: "56.25%" }}
                  key={index}
                >
                  <div className="absolute top-0 left-0 block w-full h-full text-center">
                    <div className="block w-full h-full">
                      <Image
                        image={item.image}
                        className="object-contain w-full h-full aspect-video"
                        aspectRatio="16by9"
                        fitIn={item.image_fitin}
                        fillColor="E6E6E6"
                      />
                    </div>
                  </div>
                </div>
                {(item.caption?.length > 0 ||
                  item.image?.title?.length > 0 ||
                  item.image?.copyright?.length > 0) && (
                  <div className="absolute inset-x-0 bottom-0 bg-white bg-opacity-50">
                    <div className="w-full max-w-screen-lg mx-auto my-4 ">
                      <div className="lg:-mx-2.5">
                        <figcaption className="px-grid">
                          {item.caption?.length > 0 ? (
                            <span className="mr-4">{item.caption}</span>
                          ) : item.image?.title?.length > 0 ? (
                            <span className="mr-4">{item.image.title}</span>
                          ) : (
                            ""
                          )}
                          {item.image?.copyright?.length > 0 && (
                            <small>© {item.image.copyright}</small>
                          )}
                        </figcaption>
                      </div>
                    </div>
                  </div>
                )}
              </figure>
            ))}
        </Slider>
      </div>
    </BlockWrapper>
  )
}

export default SliderGalleryBlock
